import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const DonutWithIcon = ({ before, value, after, label, labelKey, icon, isFetching }) => (
    <div className="item my-1">
        <div className="header-title">{label} <sup>{labelKey}</sup></div>
        <div className="inner">
            <div className="circle">
                <svg viewBox="0 0 38 38" className="img-fluid">
                    <circle className="circle-bg" cx="19" cy="19" r="16" fill="none" strokeWidth="2" />
                    <circle transform="rotate(-90 19 19)"
                        className="circle-highlight"
                        fill="none"
                        stroke="#C40E0E"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeDasharray={`${isFetching ? 0 : value}, 100`}
                        cx="19"
                        cy="19"
                        r="16" />
                </svg>
            </div>
            <div className="text">
                <span className="display-2">
                    {icon}
                </span>
            </div>
        </div>
        <div className="sub-title">{isFetching ? 'Loading...' : `${before || ''}${value || '0'} ${after || ''}`}</div>
    </div>
);

DonutWithIcon.propTypes = {
    before: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    after: PropTypes.string,
    label: PropTypes.string,
    labelKey: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    icon: PropTypes.object,
    // 250317 YUANKUI: FIX THX-153: Page doesn't load until this has updated to make it clearer
    isFetching: PropTypes.bool,
};

DonutWithIcon.defaultProps = {
    before: '',
    value: '',
    after: '',
    label: '',
    labelKey: '',
    icon: '',
    // 250317 YUANKUI: FIX THX-153: Page doesn't load until this has updated to make it clearer
    isFetching: true,
};

export default DonutWithIcon;
