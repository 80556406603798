import React, { useEffect, useState } from 'react';
import { Select, Button, Spin, Row, Col, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AddSiteModal from './AddSiteModal';
import AddContactModal from './AddContactModal';
import { getCustomerContactsRequest, getCustomerSitesRequest } from '../../../core/account/accountActions';
import './style.scss';

const DeliveryDetails = () => {
    const dispatch = useDispatch();
    const {
        customerSites,
        isFetchingCustomerSites,
        customerContacts,
        isFetchingCustomerContacts,
    } = useSelector(state => state.account);
    const [showAddSiteModal, setShowAddSiteModal] = useState(false);
    const [showAddContactModal, setShowAddContactModal] = useState(false);
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedContact, setSelectedContact] = useState(null);
    const [selectedCCContact, setSelectedCCContact] = useState(null);

    const onChangeSite = (val) => {
        // 20241013 YUANKUI TRY TO FIX TH-37
        //          When val is an object, display it.
        if (val && typeof val === 'object') {
            setSelectedSite(val);
            document.getElementById('address_id').value = val.ADR_ID;
        } else {
            // When val is not an object, use it as ADR_ID to find the site object and display.
            setSelectedSite(customerSites.find(site => site.ADR_ID === val));
            document.getElementById('address_id').value = val;
        }
    };

    const onChangeContact = (val) => {
        // 20241015 YUANKUI TRY TO FIX TH-37
        //          When val is an object, display it.
        if (val && typeof val === 'object') {
            setSelectedContact(val);
            document.getElementById('site_user_id').value = val.id;
        } else {
            // When val is not an object, use it as id to find the contact object and display.
            setSelectedContact(customerContacts.find(contact => contact.id === val));
            document.getElementById('site_user_id').value = val;
        }
    };

    const onChangeCCContact = (val) => {
        // 241227 YUANKUI: THX-117: Allow an order confirmation to be CC'd to another customers contact.
        if (val && typeof val === 'object') {
            setSelectedCCContact(val);
            document.getElementById('cc_user_id').value = val.id;
        } else {
            // When val is not an object, use it as id to find the contact object and display.
            setSelectedCCContact(customerContacts.find(contact => contact.id === val));
            document.getElementById('cc_user_id').value = val;
        }
    };

    useEffect(() => {
        dispatch(getCustomerSitesRequest());
        dispatch(getCustomerContactsRequest());
    }, []);

    return (
        <div className="delivery-details-container">
            {!isFetchingCustomerSites ? (
                <>
                    <Row>
                        <Col span={9}>Site</Col>
                        <Col span={13}>
                            <Form.Item required>
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) => (Object.values(option).join(',')).toLowerCase().includes(input.toLowerCase())}
                                    key={selectedSite ? selectedSite.ADR_ID : undefined}
                                    value={selectedSite ? selectedSite.ADR_ID : undefined}
                                    defaultValue="Select a site.."
                                    style={{ width: '100%' }}
                                    onChange={(val) => onChangeSite(val)}>
                                    {customerSites.map(site => (
                                        <Select.Option value={site.ADR_ID}>{site.ADR_NAME}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button onClick={() => setShowAddSiteModal(true)}>+</Button>
                            <AddSiteModal setShowModal={setShowAddSiteModal} showModal={showAddSiteModal} onActionComplete={onChangeSite} />
                        </Col>
                    </Row>
                    {selectedSite && (
                        <Row>
                            <Col span={24} className="selected-site-table-container">
                                <table className="selected-site-table">
                                    <tr>
                                        <th>ADDRESS LINE 1</th>
                                        <td>{selectedSite.ADR_LINE1}</td>
                                    </tr>
                                    <tr>
                                        <th>ADDRESS LINE 2</th>
                                        <td>{selectedSite.ADR_LINE2}</td>
                                    </tr>
                                    <tr>
                                        <th>TOWN/CITY</th>
                                        <td>{selectedSite.ADR_TOWN}</td>
                                    </tr>
                                    <tr>
                                        <th>COUNTY</th>
                                        <td>{selectedSite.ADR_COUNTY}</td>
                                    </tr>
                                    <tr>
                                        <th>POSTCODE</th>
                                        <td>{selectedSite.ADR_POSTCODE}</td>
                                    </tr>
                                </table>
                            </Col>
                        </Row>
                    )}
                </>
            ) : <Spin />}
            {!isFetchingCustomerContacts ? (
                <Row>
                    <Col span={9}>Contact</Col>
                    <Col span={13}>
                        <Form.Item required>
                            <Select
                                allowClear
                                showSearch
                                filterOption={(input, option) => (Object.values(option).join(',')).toLowerCase().includes(input.toLowerCase())}
                                key={selectedContact ? selectedContact.id : undefined}
                                value={selectedContact ? selectedContact.id : undefined}
                                defaultValue="Select a contact.."
                                style={{ width: '100%' }}
                                onChange={(val) => onChangeContact(val)}>
                                {customerContacts.map(contact => (
                                    <Select.Option value={contact.id}>{contact.firstName} {contact.lastName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Button onClick={() => setShowAddContactModal(true)}>+</Button>
                        <AddContactModal setShowModal={setShowAddContactModal} showModal={(showAddContactModal)} onActionComplete={onChangeContact} />
                    </Col>
                </Row>
            ) : <Spin />}
            {!isFetchingCustomerContacts ? (
                <Row>
                    <Col span={9}>CC Contact</Col>
                    <Col span={13}>
                        <Form.Item>
                            <Select
                                allowClear
                                showSearch
                                filterOption={(input, option) => (Object.values(option).join(',')).toLowerCase().includes(input.toLowerCase())}
                                key={selectedCCContact ? selectedCCContact.id : undefined}
                                value={selectedCCContact ? selectedCCContact.id : undefined}
                                defaultValue="Select a CC contact.."
                                style={{ width: '100%' }}
                                onChange={(val) => onChangeCCContact(val)}>
                                {customerContacts.map(contact => (
                                    <Select.Option value={contact.id}>{contact.firstName} {contact.lastName}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Button onClick={() => setShowAddContactModal(true)}>+</Button>
                        <AddContactModal setShowModal={setShowAddContactModal} showModal={(showAddContactModal)} onActionComplete={onChangeCCContact} />
                    </Col>
                </Row>
            ) : <Spin className="invisible" />}
        </div>
    );
};

export default DeliveryDetails;
